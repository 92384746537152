// Init Lazy Frame
//
// uses:
// - https://github.com/vb/lazyframe
//
(function (window) {
  function initLazyFrame() {
    // Passing a nodelist
    var $iframeElements = document.querySelectorAll('[data-lazyframe]');
    lazyframe($iframeElements);
  }

  document.addEventListener('pjax:success', initLazyFrame);
  initLazyFrame();
})(this);
